import { QueryClientProvider } from "@tanstack/react-query";
import { AppType } from "next/app";
import Head from "next/head";
import Script from "next/script";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { Suspense } from "react";
import { bootstrap } from "~/bootstap";
import { useAppInfo, useAppRouterAnalytics, useCliKey } from "~/dataProcessor/hooks/app";
import { Toaster } from "~/design-system/atom/toaster";
import { queryClient } from "~/queryClient";
import { mobileModeCSS } from "~/styles/emotions/global";
import "~/styles/globals.css";
import { ErrorBoundary } from "~/ui/components/base/errorBoundary";
import { LoadingFull } from "~/ui/components/base/loadingFull";
import { MobileNotAvailableBanner } from "~/ui/components/base/not_available_on_mobile";
import { SSRWrapper, SidebarLayoutWrapper } from "~/ui/layout/base";
import { INTERCOM_SCRIPT } from "~/utils/scriptUtil";
import { setThemeHTMLFunction } from "~/utils/theme_utils";
import "../styles/github.css";
bootstrap();

const MyApp: AppType<any> = ({ Component, pageProps }) => {
    const { isRootPath, isMobileMode, isSEOPath, isNonSidebarPage } = useAppInfo();
    useCliKey();
    useAppRouterAnalytics();

    return (
        <SSRWrapper isSEOPath={isSEOPath}>
            <Head>
                <CommonHead isRootPath={isRootPath} isSEOPath={isSEOPath} />
            </Head>
            {isMobileMode && !isSEOPath && (
                <div className={mobileModeCSS}>
                    <MobileNotAvailableBanner showLoader />
                </div>
            )}
            <ErrorBoundary fallback={<div>Error loading component</div>}>
                <Suspense fallback={<LoadingFull showLoader />}>
                    <PostHogProvider client={posthog}>
                        <QueryClientProvider client={queryClient}>
                            <SidebarLayoutWrapper isNonSidebarPage={isNonSidebarPage}>
                                <Component {...pageProps} />
                            </SidebarLayoutWrapper>
                            <div id="login-prompt"></div>
                            <Toaster />
                            <div id="modal-root"></div>
                        </QueryClientProvider>
                        <AppLevelScripts />
                    </PostHogProvider>
                </Suspense>
            </ErrorBoundary>
        </SSRWrapper>
    );
};

const CommonHead = ({ isRootPath, isSEOPath }: { isRootPath: boolean; isSEOPath: boolean }) => (
    <>
        <title>Composio.dev</title>
        <meta name="theme-color" content="#000000" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        {!isRootPath && !isSEOPath && <meta name="robots" content="noindex" />}
        <script
            dangerouslySetInnerHTML={{
                __html: setThemeHTMLFunction,
            }}
        />
    </>
);

const AppLevelScripts = () => {
    const isDev = process.env.NODE_ENV === "development";
    if (isDev) {
        return null;
    }
    return (
        <>
            <Script
                id="intercom"
                dangerouslySetInnerHTML={{
                    __html: INTERCOM_SCRIPT,
                }}
            />
            <Script
                defer
                data-domain="composio.dev"
                data-api="https://pa.composio.dev/api/event"
                src="https://pa.composio.dev/js/script.tagged-events.js"
            />
            <Script src="https://www.googletagmanager.com/gtag/js?id=G-CWE0CCJVF2" crossOrigin="anonymous" />
        </>
    );
};

export default MyApp;
