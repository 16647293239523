import { css } from "@emotion/css";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { clsx } from "clsx";
import * as React from "react";

const buttonVariants = cva(
    " inline-flex transition-all items-center justify-center whitespace-nowrap  text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-60",
    {
        variants: {
            variant: {
                white: "",
                default: "",
                black: "",
            },
            size: {
                default: "h-11 text-[12.5px] rounded-[12px] px-4 text-[13px] transition",
                24: "!h-[24px] rounded-[8px] px-3 text-[12.5px] font-normal tracking-[.3px] ",
                28: "!h-[28px] rounded-[8px] px-3 text-[13px] font-[500]",
                36: "!h-[36px] rounded-[10px] px-3 text-[13px] font-normal tracking-[.3px] ",
                32: "!h-[32px] rounded-[10px] px-3 text-[13px] font-normal tracking-[.3px] ",
                40: "!h-[40px] rounded-[12px] px-3 text-[13.5px] font-normal tracking-[.3px] ",
                icon: "h-10 w-10",
            },
        },
        defaultVariants: {
            variant: "white",
            size: "default",
        },
    },
);

const whiteButtonCSS = css`
    background: #f0f0f036;

    height: 28px;
    border: 0.5px solid #cacaca;
    color: #404040;
    box-shadow:
        lch(0 0 0 / 0.02) 0px 4px 4px -1px,
        lch(0 0 0 / 0.03) 0px 1px 1px 0px !important;
    cursor: default !important;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    font-size: 13px;
    font-weight: 500;
`;

const blackButtonCSS = css`
    background: #1e1e1e;
    border: 0.5px solid #8f8f8f6b;
    color: #ececec;
    box-shadow:
        lch(0 0 0 / 0.02) 0px 4px 4px -1px,
        lch(0 0 0 / 0.03) 0px 1px 1px 0px !important;
    transition: all 0.25s ease-in-out;
    cursor: default !important;

    height: 28px;

    font-size: 13px;
    font-weight: 500;
    font-size: 13px;
    font-weight: 500;
`;

const normalButtonCSS = css`
    background: #782bf5;
    border: 0.5px solid #e9d3ff;
    color: #fff;
    box-shadow:
        lch(0 0 0 / 0.05) 0px 4px 4px -1px,
        lch(0 0 0 / 0.07) 0px 1px 1px 0px !important;
    transition: all 0.25s ease-in-out;
    cursor: default !important;
    padding: 1px 12px;
    height: 32px;
    border-radius: 12px;
    font-size: 13px;
    font-weight: 500;
    font-size: 13px;
    font-weight: 500;
    :hover {
        filter: brightness(0.9);
    }
`;

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
    asChild?: boolean;
}

const NewButton = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    const isDisabled = props.disabled;
    return (
        <Comp
            title={isDisabled ? "This button is currently disabled" : ""}
            className={clsx(
                buttonVariants({ variant, size, className }),
                {
                    [variant === "white" ? whiteButtonCSS : variant === "black" ? blackButtonCSS : normalButtonCSS]: true,
                },
                css`
                    cursor: ${isDisabled ? "not-allowed" : "pointer"} !important;
                `,
            )}
            ref={ref}
            {...props}
        />
    );
});
NewButton.displayName = "Button";

export { NewButton as Button, buttonVariants };
