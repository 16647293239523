import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { LoadingIcon } from "~/ui/components/base/loadingIcon";

export const LoadingFull = ({ showLoader }: { showLoader: boolean }) => (
    <AnimatePresence>
        {showLoader && (
            <motion.div
                initial={{
                    opacity: 0,
                }}
                animate={{
                    opacity: 1,
                }}
                exit={{
                    opacity: 0,
                }}
                transition={{
                    duration: 0.25,
                }}
                className={clsx(
                    "fixed left-0 top-0 z-[100] flex h-[100vh] w-[100vw] items-center justify-center  bg-[white] pb-[100px] text-black-200",
                )}
            >
                <div className="z-4 relative">
                    <LoadingIcon size={48} />
                    <div className="ml-[-9px] mt-3 flex  gap-4 text-[14.5px] text-[black-200]">Loading...</div>
                </div>
            </motion.div>
        )}
    </AnimatePresence>
);
